import './App.css';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Intro from './components/Intro/Intro';
import ProductList from './components/ProductList/ProductList';

function App() {

  return (
    <div className="App">
      <Intro />
      <About />
      <ProductList />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
