import React from "react";
import "./Product.css";

const Product = ({ link, img, title, desc, techs }) => {
  return (
    <div className="p-container">
      <div className="p">
        <div className="p-browser">
          <div className="p-circle c-red"></div>
          <div className="p-circle c-yellow"></div>
          <div className="p-circle c-green"></div>
          <p className="browser-text">{link}</p>
        </div>
        <a href={link} target="_blank" rel="noreferrer">
          <img src={img} alt="product img" className="p-img" />
        </a>
      </div>
      <div className="p-desc">
        <h1 className="p-desc-title" style={{ color: "#eeeeee" }}>
          {title}
        </h1>
        <p className="p-desc-desc" style={{ color: "#eeeeee", width: "80%" }}>
          {desc}
        </p>
        <div className="desc-icons">
          {techs?.map((i) => (
            <i className={`a-tech-item ${i}`}></i>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;
