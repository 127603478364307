import React from "react";
import Product from "../Product/Product";
import "./ProductList.css";
import { products } from "../../data";
import "animate.css";
import TrackVisibility from "react-on-screen";

const ProductList = () => {
  return (
    <div className="pl">
      <div className="pl-texts">
        <TrackVisibility>
          {({ isVisible }) =>
            isVisible ? (
              <h1 className="pl-title animate__animated animate__pulse">
                Build and Develop, from Mert.
              </h1>
            ) : (
              <h1 className="pl-title">Build and Develop, from Mert.</h1>
            )
          }
        </TrackVisibility>

        <p className="pl-desc">
          I love building websites and applications. Therefore, displaying some
          of my previous works below. These projects mostly use both ends of
          development. If you want to preview my other projects, you can always
          check my Github repo or if you have any questions about me, feel free
          to contact me. PS: Some of my websites are not hosted but you can
          check github repos for source codes.
        </p>
      </div>
      <div className="pl-list">
        {products.map((i) => (
          <Product
            key={i.id}
            img={i.img}
            link={i.link}
            title={i.title}
            desc={i.desc}
            techs={i.techs}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
