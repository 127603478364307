import React, { useRef } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_of4p91d",
        "template_myveh6s",
        formRef.current,
        "Umj6Fbt82w8X94eUZ"
      )
      .then(
        (result) => {
          alert("Message sent. Thank you!");
          formRef.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="c">
      <div className="c-bg"></div>
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Feel free to contact with me.</h1>
          <div className="c-info">
            {/* <div className="c-info-item">
              <i class="fa-solid fa-phone"></i>
            </div> */}
            <div className="c-info-item">
              <a
                href="mailto:mertarinci96@gmail.com"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <i
                  class="fa-solid fa-envelope"
                  style={{ marginRight: "15px" }}
                ></i>
                mertarinci96@gmail.com
              </a>
            </div>
            <div className="c-info-item">
              <i className="fa-solid fa-globe"></i>
              arinci.nl
            </div>
            <div className="c-info-item ret">
              <i className="fa-solid fa-location-dot"></i>
              Rotterdam, NL
            </div>
          </div>
        </div>
        <div className="c-right">
          <p className="c-desc">
            <span style={{ fontWeight: "bold" }}>
              Form is under construction.
            </span>{" "}
            It will work but i need to fix my webmail first :{")"} You can ask,
            why you have form, if it's not working? I have form because i want
            to show you that i can use form and i can send email with form. I
            will fix it soon. Thank you for your understanding. (Chatgpt created
            this text it's cool lol)
          </p>

          <form ref={formRef} onSubmit={handleSubmit}>
            <input required type="text" placeholder="Name" name="user_name" />
            <input
              required
              type="text"
              placeholder="Subject"
              name="user_subject"
            />
            <input required type="text" placeholder="Email" name="user_email" />
            <textarea
              required
              row="5"
              placeholder="Message"
              name="user_message"
            />
            <button className="user-submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
