import React from "react";
import "./Intro.css";
import headerImg from "../../img/header-img.svg";

import "animate.css";

const Intro = () => {
  const nextComp = () => {
    document.querySelector(".a").scrollIntoView();
  };
  const toContact = () => {
    document.querySelector(".c").scrollIntoView();
  };

  return (
    <div className="i">
      <div className="i-left">
        <div className="i-left-wrapper">
          <h2 className="i-intro">Hello, My name is</h2>
          <h1 className="i-name">Mert A.</h1>
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">Fullstack Developer</div>
              <div className="i-title-item">iOS Developer</div>
              <div className="i-title-item">Wordpress Developer</div>
              <div className="i-title-item">Web Developer</div>
              <div className="i-title-item">Native Mobile Dev.</div>
            </div>
          </div>
          <p className="i-desc">
            I design and develop websites/applications with both front-end and
            back-end tools. I love challanges and keep learning new
            technologies. Programming is a passion of mine.
          </p>
          <button className="i-btn" onClick={toContact}>
            Contact Me
          </button>
        </div>
      </div>
      <div className="i-right">
        <img className="i-img" src={headerImg} alt="header svg" />
      </div>
      <i onClick={nextComp} className="fa-solid fa-arrow-down i-scroll"></i>
    </div>
  );
};

export default Intro;
