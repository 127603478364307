import React, { useState } from "react";
import "./About.css";
import mertImg from "../../img/MertFoto4.jpg";
import mertImg2 from "../../img/MertFoto5.jpg";
import mertImg3 from "../../img/MertFoto6.jpg";
import mertImg4 from "../../img/MertFoto7.jpg";

const About = () => {
  const [mouseIn, setMouseIn] = useState(false);

  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            onMouseEnter={() => setMouseIn(true)}
            onMouseLeave={() => setMouseIn(false)}
            onClick={() => setMouseIn(!mouseIn)}
            className="a-img"
            src={mertImg3}
            alt="mert"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          Full Stack Web & Mobile Developer based in Rotterdam, the Netherlands.
        </p>
        <p className="a-desc">
          I began my coding journey when I was in college. I started with basic
          data science projects but then found myself in the digital ocean of
          the web. I started to learn HTML, CSS and JavaScript for basic
          projects. After that, I jumped into frameworks such as React and
          Angular and the backend part of the applications. Right now, I am
          using NodeJS for a backend server but still learning C# .NET because I
          love challenges. For database purposes, I am using MySQL, Firestore
          and MongoDB. I actively use Github for version control and store my
          older projects in. Below, you can find which tools and softwares I
          mostly use.
          <br />
          <br />
          <i>
            Coding is a passion for me because I love challenges and am obsessed
            with harder tasks.
          </i>
        </p>
        <div className="a-techs">
          <i className="a-tech-item fa-brands fa-react"></i>
          <i className="a-tech-item fa-brands fa-angular"></i>
          <i className="a-tech-item fa-solid fa-database"></i>
          <i className="a-tech-item fa-brands fa-node"></i>
          <i className="a-tech-item fa-brands fa-square-js"></i>

          <i className="a-tech-item fa-brands fa-sass"></i>
          <i className="a-tech-item fa-brands fa-swift"></i>
          <i className="a-tech-item fa-brands fa-apple"></i>
          <i className="a-tech-item fa-brands fa-github"></i>
          <i className="a-tech-item fa-brands fa-docker"></i>
          <i className="a-tech-item fa-brands fa-wordpress"></i>
        </div>
      </div>
      {/* <i onClick={nextComp} className="fa-solid fa-arrow-down a-scroll"></i> */}
    </div>
  );
};

export default About;
