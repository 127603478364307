export const products = [

    {
        id: 1,
        img: "images/mertblog.png",
        link: "https://github.com/mertarinci/react-mysql-blog",
        title: "Mertblog - Blog Website",
        desc: "My first complex blog website that allows you to create your own profile and edit your own posts. In this website, you can register/login, create new posts with special 'new post' page and edit your own posts. You can add photos for your posts and your profile. This website contains both frontend and backend tools.",
        techs: ["fa-brands fa-react", "fa-brands fa-node", "fa-solid fa-database", "fa-brands fa-github", "fa-brands fa-sass", "fa-brands fa-docker"]
    },
    {
        id: 2,
        img: "images/mertshop.png",
        link: "https://github.com/mertarinci/mertshop-react-ecomproj",
        title: "Mertstore - eCommerce Website",
        desc: "This website is a fully functioning e-commerce website. Admin and employee panels added to CMS system so you can add,remove and edit items easily. Admin and Product panels are not code required so any end-user can easily use those panels for their online store. Payment system works and powered with Stripe.",
        techs: ["fa-brands fa-react", "fa-brands fa-node", "fa-solid fa-database", "fa-brands fa-bootstrap", "fa-brands fa-github", "fa-brands fa-sass", "fa-brands fa-stripe"]

    },
    {
        id: 3,
        img: "images/alchemist.PNG",
        link: "https://alchemist.arinci.nl/",
        title: "The Alchemist - Idle Clicker Game",
        desc: "This website my first attempt to make a text-based game with ReactJS. Game contains a little story and challanges. I challanged myself to make full functioning real-time idle clicker game. You can always play my game and give me feedback about anything. I am also working on mobile version of this game and that will be more complex and animated for iOS and Android.",
        techs: ["fa-brands fa-react", " fa-brands fa-github", " fa-brands fa-sass", " fa-brands fa-docker"]
    },
    {
        id: 4,
        img: "images/kittyfeed.PNG",
        link: "https://github.com/mertarinci/rc-mysql-social_media-fe",
        title: "Kittyfeed - Social Media App",
        desc: "This website is a 'Blue bird' clone that cat lovers can share their ideas and learn about cats even more! My main purpose to clone well-known social media app that i can understand how things work behind. I worked on backend more than building an UI/UX. Main features: Share post, follow people, create trending topics, live messages.",
        techs: ["fa-brands fa-react", "fa-brands fa-node", "fa-solid fa-database", "fa-brands fa-github", "fa-brands fa-sass"]
    },
    // {
    //     id: 5,
    //     img: "https://bootstrapmade.com/content/templatefiles/Yummy/Yummy-bootstrap-website-template-md.png",
    //     link: "https://mertarinci.com/foods"
    // },
    // {
    //     id: 6,
    //     img: "images/mertshopcheck.PNG",
    //     link: "https://mertarinci.com/shop"
    // }
]