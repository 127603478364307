import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="top">
        <div className="pInfo">
          <div className="item">
            <i class="fa-solid fa-location-dot"></i>
            <p>Based in Rotterdam</p>
          </div>
          <div className="item">
            <i class="fa-solid fa-flag"></i>
            <p>From Izmir</p>
          </div>
        </div>
        <div className="contact">
          <h4>Site built with React.</h4>
        </div>
        <div className="contact-me">
          <div className="items">
            <i class="fa-brands fa-twitter"></i>
            <a
              href="http://github.com/mertarinci"
              target="_blank"
              rel="noreferrer"
            >
              <i class="fa-brands fa-github"></i>
            </a>
            <i class="fa-brands fa-linkedin"></i>
            <i class="fa-brands fa-youtube"></i>
          </div>
        </div>
      </div>
      <div className="stickman-div">
        <div className="stickman">
          <div className="head">
            <div className="eye"></div>
            <div className="mouth"></div>
          </div>
          <div className="body">
            <div className="arm" id="left"></div>
            <div className="arm" id="right"></div>
            <div className="leg" id="left"></div>
            <div className="leg" id="right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
